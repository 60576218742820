import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminAPI } from "../api/adminAPI";
import { appLoading } from "./appSlice";
// async thunk request to get calls list
export const userRequest = createAsyncThunk(
  "user/userRequest",
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await adminAPI.getuserRequest();
      dispatch(getUserDetails(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
const initialState = {
  userDetails: {},
  hasError: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
  },
  extraReducers: {
    [userRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const { getUserDetails } = userSlice.actions;

export default userSlice.reducer;
