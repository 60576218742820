import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
} from "recharts";
import TopCompo from "../components/dashBoard/TopCompo";

const DashBoard = (props) => {
  const data = [
    { name: "Page A", uv: 400, pv: 2000, amt: 2320 },
    { name: "Page B", uv: 200, pv: 2200, amt: 2400 },
    { name: "Page C", uv: 240, pv: 2140, amt: 2400 },
    { name: "Page D", uv: 300, pv: 2220, amt: 2400 },
    { name: "Page E", uv: 150, pv: 2140, amt: 2400 },
  ];
  const data2 = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  // const data02 = [
  //   { name: "A1", value: 100 },
  //   { name: "A2", value: 300 },
  //   { name: "B1", value: 100 },
  //   { name: "B2", value: 80 },
  //   { name: "B3", value: 40 },
  //   { name: "B4", value: 30 },
  //   { name: "B5", value: 50 },
  //   { name: "C1", value: 100 },
  //   { name: "C2", value: 200 },
  //   { name: "D1", value: 150 },
  //   { name: "D2", value: 50 },
  // ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {}, [props.show]);

  return (
    <div className="w-[90%] mx-auto lg:w-full">
      <TopCompo />
      <div className={`lg:flex grid grid-rows-3`}>
        <div className=" w-full bg-secoundry dark:bg-darkmodeSecoundry shadow-dhruv  justify-center items-center ">
          <h2 className=" font-medium mb-4 px-4 pt-4 text-indigo-600 dark:text-darkmodeFots">
            Today's Clicks
          </h2>

          <ResponsiveContainer width="100%" height={280}>
            <BarChart data={data} margin={{ right: 30, left: 0 }}>
              <XAxis dataKey="name" stroke="#8884d8" />
              <YAxis />
              <Tooltip wrapperStyle={{ width: 80, backgroundColor: "#ccc " }} />
              <Legend
                width={100}
                wrapperStyle={{
                  top: 30,
                  right: 10,
                  backgroundColor: "#f5f5f5",
                  border: "1px solid #d5d5d5",
                  borderRadius: 3,
                  lineHeight: "20px",
                }}
              />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <Bar dataKey="uv" fill="#8884d8" barSize={20} />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className=" bg-secoundry overflow-x-hidden  drop-shadow-xl lg:ml-9 p-4 dark:bg-darkmodeSecoundry w-full">
          <h2 className=" font-medium mb-4 px-4 text-indigo-600 dark:text-darkmodeFots">
            User Engagement
          </h2>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              data={data}
              margin={{
                top: 2,
                right: 20,
                left: 0,
                bottom: 2,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className=" bg-secoundry drop-shadow-xl lg:ml-8  dark:bg-darkmodeSecoundry w-full">
          <h2 className=" font-medium mb-4 px-6 pt-4 text-indigo-600 dark:text-darkmodeFots">
            Clients
          </h2>
          <ResponsiveContainer width="100%" height={270}>
            <PieChart>
              <Pie
                data={data2}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {data2.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
