import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  currentPage: 1,
  postsPerPage: 20,
};

let paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPostNumber: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setPostNumber } = paginationSlice.actions;
export default paginationSlice.reducer;
